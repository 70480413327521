// const hostname = window.location.hostname.split('.').slice(-2).join('.');
// production Url

const siteUrl = 'https://api.foxearch.com/';

// Development Url
// const siteUrl = 'http://ec2-43-204-115-130.ap-south-1.compute.amazonaws.com:8000/'
// const siteUrl = 'http://ec2-13-126-193-144.ap-south-1.compute.amazonaws.com:8000/'
// const siteUrl = "http://ec2-3-110-114-243.ap-south-1.compute.amazonaws.com:8000/"
// const siteUrl = "http://ec2-13-201-192-235.ap-south-1.compute.amazonaws.com:8000/"

// export async function UploadFile(url, body, token) {
//   // console.log("====token==>")

//   const init = { 
//     method: 'POST',
//     headers: {
//       token: token,
//       // 'Content-Type': 'multipart/form-data',
//       // 'content-Type': 'multipart/form-data; boundary=--------------------------386650167365198011592528'
//     },
//     body: body,
//   };   
//   try {
//     const res = await fetch(siteUrl + url, init);
//     const data = await res.json();
//     var apiData = {
//       status: res.status,
//       data: data,
//     };
//     return apiData;
//   } catch (err) {
//     return 'error';
//   }
// }

export async function Api(url, apiMethod, body, tokenn) {
  const init = apiMethod === 'GET'? {
          method: 'GET',
          headers: {
            //  'Authorization': token
            'Content-Type': 'application/json',
            token: tokenn,
          },
        }
      : {
          method: apiMethod,
          headers: {
            token: tokenn,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        };

  try {
    const res = await fetch(siteUrl + url, init);
    // const data = await res.json();
    const apiData = {
      status: res.status,
      data: await res.json()
    };
    return apiData;
  } catch (err) {
    return 'error';
  }
}


export async function UploadFile(url, bodyy, tokenn) {
  // console.log("====token==>")

  const init = {
    method: 'POST',
    headers: {
      token: tokenn,
      // 'Content-Type': 'multipart/form-data',
      // 'content-Type': 'multipart/form-data; boundary=--------------------------386650167365198011592528'
    },
    body:bodyy
  };
  try {
    const res = await fetch(siteUrl + url, init);
    
     const dataa = await res.json();
    // console.log("res",dataa);
    const apiData = {
      status: res.status,
      data: dataa
    };
    return apiData;
  } catch (err) {
    return 'error';
  }
}